import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'createSalesAgreement',
  props: ['salesAgmtConsumption'],
  components: {
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getSalesAgmtConsumptionDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSalesAgmtConsumptionDetails();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      parent_value_set_id: null,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showValueSetModal: false,
      project: {
        value: null,
        text: null
      },
      salesAgreement: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      salesAgreementComp: {
        value: null,
        text: null
      },
      selectedBillingPeriod: {
        value: null,
        text: null
      },
      compName: null,
      salesConsumptionHdrId: 0,
      salesAgmtConsumptionDetailsData: [
        {
          sa_consumption_dtl_id: 0,
          component_name: null,
          component_uom: null,
          comp_meaning: null,
          quantity: 0,
          rate: 0,
          total: 0,
          hsn_code: null,
          sac_code: null,
          tax_cat_name: null,
          tax_cat_hdr_id: 0
        }
      ],
      salesAgmtConsumptionDetailsFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'component_name',
          label: 'Particulars'
        },
        {
          key: 'comp_meaning',
          label: 'Unit'
        },
        {
          key: 'quantity'
        },
        {
          key: 'rate'
        },
        {
          key: 'total'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'tax_cat_name',
          label: 'Tax Category'
        }
      ],
      rowDetails: null,
      specIndex: null
    };
  },
  validations: {
    project: {
      text: {
        required
      }
    },
    salesAgreement: {
      text: {
        required
      }
    },
    salesAgreementComp: {
      text: {
        required
      }
    },
    selectedBillingCycle: {
      text: {
        required
      }
    },
    selectedBillingPeriod: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.salesAgmtConsumption) {
      this.setSalesAgmtConsumptionDetails(this.salesAgmtConsumption);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditSalesAgmtConsumptionDetails();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    setSalesAgmtConsumptionDetails(item) {
      this.salesConsumptionHdrId = item.sa_consumption_hdr_id;
      this.project = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
      this.salesAgreement = {
        value: item.sa_agreement_hdr_id,
        text: item.document
      };
      this.selectedBillingCycle = {
        value: item.billing_cycle_hdr_id,
        text: item.billing_cycle_name
      };
      this.salesAgreementComp = {
        value: item.sa_agreement_comp_id,
        text: item.component_description
      };
      this.selectedBillingPeriod = {
        value: item.billing_cycle_period_id,
        text: item.billing_period
      };
      this.getSalesAgmtConsumptionDetails();
    },
    getSalesAgmtConsumptionDetails() {
      const requestPayload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        comp_name: this.compName
      };
      const payload = {
        saCompHdrId: this.salesConsumptionHdrId,
        requestPayload: requestPayload
      };
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getSalesAgmtConsumptionDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.page.length) {
            this.salesAgmtConsumptionDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditSalesAgmtConsumptionDetails() {
      const salesAgmtConsumptionDetails = [];
      this.salesAgmtConsumptionDetailsData.map(item => {
        salesAgmtConsumptionDetails.push({
          sales_agreement_consumption_dtl_id: item.sa_consumption_dtl_id,
          component_name: item.component_name,
          component_uom: item.component_uom,
          quantity: item.quantity,
          rate: item.rate,
          hsn_code: item.hsn_code,
          sac_code: item.sac_code,
          tax_cat_hdr_id: item.tax_cat_hdr_id
        });
      });
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          sales_agreement_consumption_hdr_id: this.salesConsumptionHdrId,
          sales_agmt_comp_id: this.salesAgreementComp.value,
          billing_cycle_period_id: this.selectedBillingPeriod.value,
          sales_agreement_hdr: salesAgmtConsumptionDetails
        };
        this.loader = true;
        this.$store
          .dispatch('salesAgreement/saveSalesAgmtConsumptionDetails', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.salesConsumptionHdrId =
                response.data.data.sa_consumption_hdr_id;
              this.editMode = false;
              this.isSuccess = true;
              this.showAlert = true;
              this.responseMsg = response.data.message;
              this.getSalesAgmtConsumptionDetails();
            } else {
              this.showAlert = true;
              this.editMode = false;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.editMode = false;
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    checkValueSetDependency(vsetCode) {
      if (
        vsetCode == appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP &&
        !this.salesAgreement.value
      ) {
        alert('Please select Sales Agreement');
      } else if (
        vsetCode == appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD &&
        !this.selectedBillingCycle.value
      ) {
        alert('Please select Billing Cycle');
      } else {
        this.openValueSetModal(vsetCode);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.specIndex = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.parent_value_set_id = this.project.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP
      ) {
        this.parent_value_set_id = this.salesAgreement.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        this.parent_value_set_id = this.selectedBillingCycle.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = 1;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreement = { value: null, text: null };
        this.salesAgreementComp = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.salesAgreement = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreementComp = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP
      ) {
        this.salesAgreementComp = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle = {
          text: item.billing_cycle_name,
          value: item.billing_cycle_hdr_id
        };
        this.selectedBillingPeriod = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        this.selectedBillingPeriod = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.salesAgmtConsumptionDetailsData[this.specIndex].comp_meaning =
          item.value_meaning;
        this.salesAgmtConsumptionDetailsData[this.specIndex].component_uom =
          item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.salesAgmtConsumptionDetailsData[this.specIndex].tax_cat_hdr_id =
          item.value_set_dtl_id;
        this.salesAgmtConsumptionDetailsData[this.specIndex].tax_cat_name =
          item.value_code;
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.project = { value: null, text: null };
        this.salesAgreement = { value: null, text: null };
        this.salesAgreementComp = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.salesAgreement = { value: null, text: null };
        this.salesAgreementComp = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP
      ) {
        this.salesAgreementComp = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle = { value: null, text: null };
        this.selectedBillingPeriod = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        this.selectedBillingPeriod = { value: null, text: null };
      }
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.salesAgmtConsumptionDetailsData[
          this.specIndex
        ].comp_meaning = null;
        this.salesAgmtConsumptionDetailsData[
          this.specIndex
        ].component_uom = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.salesAgmtConsumptionDetailsData[
          this.specIndex
        ].tax_cat_hdr_id = null;
        this.salesAgmtConsumptionDetailsData[
          this.specIndex
        ].tax_cat_name = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.salesAgmtConsumptionDetailsData.push({
          sa_consumption_dtl_id: 0,
          component_name: null,
          component_uom: null,
          comp_meaning: null,
          quantity: 0,
          rate: 0,
          total: 0,
          hsn_code: null,
          sac_code: null,
          tax_cat_name: null,
          tax_cat_hdr_id: 0
        });
      }
    },
    removeRow(index) {
      this.salesAgmtConsumptionDetailsData.splice(index, 1);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
