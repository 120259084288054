import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import salesAgmtConsumptionDetails from './salesAgmtConsumptionDetails';
export default {
  name: 'SalesAgmtConsumption',
  components: {
    salesAgmtConsumptionDetails,
  },
  watch: {
    currentPage: function() {
      this.getSalesAgmtConsumption();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSalesAgmtConsumption();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      unsubscribe:null,
      currentPage: 1,
      totalRows: null,
      loader: false,
      modalTitle: null,
      salesAgmtConsumptionModal: false,
      salesAgmtConsumption: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      project: {
        value: null,
        text: null
      },
      salesAgreement: {
        value: null,
        text: null
      },
      salesAgreementComp: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      selectedBillingPeriod: {
        value: null,
        text: null
      },
      salesAgmtConsumptionData: [],
      salesAgmtConsumptionFields: [
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'document',
          label: 'Sales Agmt No.'
        },
        {
          key: 'billing_plan_name'
        },
        {
          key: 'billing_cycle_name'
        },
        {
          key: 'billing_period'
        },
        {
          key: 'component_description',
          label:'Component Desc'
        }
      ]
    };
  },
  validations: {
    project: {
      text: { required }
    },
    // salesAgreement: {
    //   text: {
    //     required
    //   }
    // },
    // salesAgreementComp: {
    //   text: {
    //     required
    //   }
    // }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHidesalesAgmtConsumptionModal(true, 'add');
          this.salesAgmtConsumption = null;
        }
      }
    });
  },
  methods: {
    getSalesAgmtConsumption() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        fms_prj_id: this.project.value,
        sa_hdr_id: this.salesAgreement.value, 
        sa_comp_id: this.salesAgreementComp.value,
        billing_cycle_period_id: this.selectedBillingPeriod.value
      };
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getSalesAgmtConsumption', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.salesAgmtConsumptionData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    checkValueSetDependency(vsetCode) {
      if (
        vsetCode == appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP &&
        !this.salesAgreement.value
      ) {
        alert('Please select Sales Agreement');
      } else if (
        vsetCode == appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD &&
        !this.selectedBillingCycle.value
      ) {
        alert('Please select Billing Cycle');
      } else {
        this.openValueSetModal(vsetCode);
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.parent_value_set_id = this.project.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP) {
        this.parent_value_set_id = this.salesAgreement.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        this.parent_value_set_id = this.selectedBillingCycle.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreement = { value: null, text: null };
        this.salesAgreementComp = { value: null, text: null };
       } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET) {
        this.salesAgreement = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreementComp = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP
      ) {
        this.salesAgreementComp = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle = {
          text: item.billing_cycle_name,
          value: item.billing_cycle_hdr_id
        };
        this.selectedBillingPeriod = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        this.selectedBillingPeriod = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.project = { value: null, text: null };
        this.salesAgreement = { value: null, text: null };
        this.salesAgreementComp = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET) {
        this.salesAgreement = { value: null, text: null };
        this.salesAgreementComp = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP
      ) {
        this.salesAgreementComp = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle = { value: null, text: null };
        this.selectedBillingPeriod = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        this.selectedBillingPeriod = { value: null, text: null };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.salesAgmtConsumption = item;
      this.salesAgmtConsumptionModal = true;
    },
    showHidesalesAgmtConsumptionModal(flag, modalTitle) {
      this.salesAgmtConsumptionModal = flag;
      this.modalTitle = modalTitle;
    },
    clearFilters() {
      this.salesAgmtConsumptionData = [];
      this.currentPage = 1;
      this.totalRows = null;
      this.project = { value: null, text: null };
      this.salesAgreement = { value: null, text: null };
      this.salesAgreementComp = { value: null, text: null };
      this.selectedBillingCycle = { value: null, text: null };
      this.selectedBillingPeriod = { value: null, text: null };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
